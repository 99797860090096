<template>
  <div @click="handleClick" class="backBtnView">
    <img src="/images/pageHead/icon-back.png" class="backIcon"/>
  </div>
</template>

<script>
export default {
  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.backBtnView{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.backIcon {
  height: 16px;
}
</style>
