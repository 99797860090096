<template>
  <div class="pageHead" v-if="showTitle">
    <p class="pageHead_title">{{ title }}</p>
    <div class="pageHead_content">
      <div class="pageHead_content_left">
        <slot name="left">
          <backIcon @click="handleBack" v-if="!hideBack" />
        </slot>
      </div>
      <div class="pageHead_content_center">
        <slot name="center" />
      </div>
      <div class="pageHead_content_right">
        <slot name="right" />
        <span class="pageHead_fx" v-if="pageHead_fx"></span>
      </div>
    </div>
  </div>
</template>

<script>
import backIcon from './backIcon'
import {isWechatEnterpriseMobile} from "@/utils/utils";

/**
 * 全局公用的头部导航
 */
export default {
  props: {
    // 是否在企业微信中使用双微导航
    isShowPageHead: {
      type: Boolean,
      default: false
    },
    title: String,
    // 隐藏返回键
    hideBack: Boolean,
    pageHead_fx: Boolean,
    // 返回键自定义事件
    special: {
      type: Boolean,
      default: false
    },
    // 是否设置网页title
    isSetDocTitle: {
      type: Boolean,
      default: true
    },
  },
  components: {
    backIcon
  },
  data(){
    return {
      // 是否显示该组件，再企业微信中默认不显示，需要显示时通过父组件控制
      showTitle: true,
    }
  },
  created() {
    if(!this.isShowPageHead){
      this.showTitle = !isWechatEnterpriseMobile();
      // if(!this.showTitle){
      //   document.title = this.title;
      // }
    }
    if(this.isSetDocTitle){
      document.title = this.title;
    }
  },
  methods: {
    handleBack() {
      this.$emit('back')
      if (!this.special) {
        this.$router.back()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pageHead {
  height: $app-view-headHeight;
  background-color: white;
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
  z-index: 10;

  &_title {
    text-align: center;
    position: absolute;
    top: 0;
    right: 80px;
    left: 80px;
    bottom: 0;
    @include subtitle;
    line-height: $app-view-headHeight;
  }

  &_content {
    padding-right: 20px;
    padding-left: 20px;
    display: flex;
    height: 100%;
    justify-content: space-between;

    &_center {
      flex: 1;
      width: 0;
    }

    &_center,
    &_left,
    &_right {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .pageHead_fx {
    width: 20px;
    height: 20px;
    background: url('~@/assets/imgs/circle/Slice45@2x.png');
    background-size: cover;
  }
}
.pageHead_title {
  line-height: 57.41px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
