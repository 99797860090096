<template>
  <div class="pageWrap">
    <pageHead title="即刻发声"/>
    <div class="main_warp">
      <div class="success_wrap">
        <img src="@/assets/imgs/main/success.png" />
        <div class="txt">发布成功</div>
      </div>
      <div class="line_text">关联员工关心的话题</div>
      <div class="select_wrap">
        <span
          :class="{'select_item': true, 'select_item_acitvie': index === activeIndex}"
          v-for="(item,index) in data"
          :key="item.value"
          @click="changeActive(index)"
        v-html="item.value">{{item.value}}</span>
      </div>
      <van-button v-show="activeIndex !== -1" type="danger" block custom-class="button-class" @click="updateCategory">查看帖子</van-button>
    </div>
  </div>
</template>

<script>
import pageHead from '@/components/pageHead'
// import {getDictBiz} from "@/api/dict-biz";
// import {articleUpdate} from "@/api/article";

export default {
  name: "index",
  components:{
    pageHead
  },
  data(){
    return {
      activeIndex: -1 ,
      data: [
      ]
    }
  },
  created() {
    this.setData()
  },
  methods:{
    changeActive(index){
      if(this.activeIndex === index){
        this.activeIndex = -1
      }else{
        this.activeIndex = index
      }
    },
    setData(){
      const localTags = window.localStorage.getItem('tags');
      if(localTags) {
        const tags = JSON.parse(localTags);
        this.data = tags.map(item => {
          return {
            value: item,
            chosen: false
          }
        })
      }
    },

     updateCategory(){
      // 添加分类成功，
      if(this.activeIndex !== -1){
        this.$router.push({name: 'searchTag', query: {tag: this.data[this.activeIndex].value}})
      }

    }
  }
}
</script>

<style  lang="scss" scoped>
.pageWrap{
  height: 100%;
  width: 100%;
  .main_warp{
    height: 100%;
    background: #ffffff;
    margin-top:1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 25px;

  }
  .success_wrap{
    width: 80px;
    height: 100px;
    margin: 0 auto 40px;
    padding-top: 30px;

    img{
      width: 80px;
      height: 80px;
    }
    .txt{
      text-align: center;
      font-size: 14px;
      color: $titleColor;
      font-weight: 500;
    }
  }
  .line_text{
    width: 100%;
    color: #6D7278;
    font-size: 14px;
    /*margin-bottom: 15px;*/
  }
  .select_wrap{
    width: 100%;
    margin-bottom: 40px;
  }

  .select_item{
    display: inline-block;
    font-size: 12px;
    border: 1px solid $lightGray;
    padding: 0 20px;
    margin: 6px 10px 0 0;
    border-radius: 14px;
    line-height: 24px;
    word-break: break-all;
  }

  .select_item_acitvie{
    color: $mainColor;
    border-color: $mainColor;
  }

  ::v-deep .van-button--danger{
    background: $mainColor;
  }
}
</style>
